<!--
 * @Author: xiaziwen
 * @Date: 2021-01-13 11:56:22
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 12:57:44
 * @FilePath: \crm-education\src\views\teacher\course\index.vue
-->
<template>
  <div>
    <a-card class="cus-card no-border" :bordered="false">
      <a-tabs v-model="activeKey" class="cus-tabs">
          <a-tab-pane key="1">
            <template slot="tab">
              <p class="fs-18">我教的课</p>
            </template>
            <div
              class="section-header d-flex align-center justify-between flex-wrap py-20"
            >
              <p class="fs-14 color-gray link color-blue">创建课程＋</p>
              <p></p>
            </div>
            <a-row :gutter="30">
              <a-col
                class="mb-10"
                :md="{ span: 8 }"
                :xs="{ span: 24 }"
                v-for="i in 6"
                :key="i"
              >
                <panel-live @onbtnclick="onPanelClick">
                  <template slot-scope="scope">
                    <p class="author fs-12">主讲：{{scope.options.author}}</p>
                    <p class="date fs-12">时间：{{scope.options.dateTime}}</p>
                    <p class="date fs-12">下期上课时间：{{scope.options.nextDateTime}}</p>
                  </template>
                </panel-live>
              </a-col>
            </a-row>
          </a-tab-pane>
          <a-tab-pane key="2">
            <template slot="tab">
              <p class="fs-18">我的直播</p>
            </template>
            <div
              class="section-header d-flex align-center justify-between flex-wrap py-10"
            >
              <p class="fs-14 color-gray link color-blue">创建直播＋</p>
              <div class="d-flex align-center">
                <a-input placeholder="请输入直播标题" v-model="searchParams.key"></a-input>
                <a-button type="primary">查询</a-button>
              </div>
            </div>
            <a-list item-layout="horizontal" :data-source="dataList" :split="false">
              <a-list-item slot="renderItem" slot-scope="item, index">
                <my-course-live-item class="text-left" :item="item" :index="index"></my-course-live-item>
              </a-list-item>
            </a-list>
          </a-tab-pane>
        </a-tabs>
    </a-card>
  </div>
</template>

<script>
import PanelLive from '@/components/business/panel/Live'
import MyCourseLiveItem from './c/MyCourseLiveItem'

export default {
  name: 'teacher_course',
  components: {
    PanelLive,
    MyCourseLiveItem
  },
  data () {
    return {
      activeKey: '1',
      searchParams: {
        key: ''
      },
      dataList: [{}, {}, {}]
    }
  },
  methods: {
    onPanelClick () {}
  }
}
</script>
