<!--
 * @Author: xiaziwen
 * @Date: 2021-01-13 12:45:23
 * @LastEditors: xiaziwen
 * @LastEditTime: 2021-01-13 12:57:05
 * @FilePath: \crm-education\src\views\teacher\course\c\MyCourseLiveItem.vue
-->
<template>
  <div class="course-item d-flex justify-between flex-wrap align-center">
    <div class="course-info d-flex">
      <div class="course-img">
        <img src="../../../../assets/img/course-item-qs.png" alt="暂时无法加载该图片">
      </div>
      <div class="px-10">
        <p class="fw-bold fs-18 mb-10">办公室管理 <a-tag color="#fdf3dc"></a-tag></p>
        <p class="fs-14 color-gray mb-10">直播时间：2019-11-01 14：00</p>
        <div class="d-flex align-center">
          <p class="fs-14 color-gray">操作：</p>
          <div class="d-flex align-center">
            <p class="ps-14 link color-blue mr-10">直播地址</p>
            <p class="ps-14 link color-blue mr-10">禁言</p>
            <p class="ps-14 link color-blue mr-10">取消禁言</p>
            <p class="ps-14 link color-blue mr-10">禁止转发</p>
            <p class="ps-14 link color-blue mr-10">只允许学习通内观看</p>
            <p class="ps-14 link color-red mr-10">删除</p>
          </div>
        </div>
      </div>
    </div>
    <div class="course-button">
      <a-button type="primary" class="cus-button" @click="onClick">{{item.btnText || '进入直播'}}</a-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'course_item',
  components: {
  },
  props: {
    item: {
      type: Object,
      default: () => ({
        btnText: '进入直播'
      })
    }
  },
  methods: {
    onClick () {
      this.$refs.courseNavigationDialog.open()
    }
  }
}
</script>

<style lang="scss" scoped>
.course-item {
  width: 100%;
  padding: 20px;
  transition: all .5s;
  &:hover {
    border-color: rgba(0,0,0,.09);
    box-shadow: 0 0 8px 2px rgba(0,0,0,.09);
    border-radius: 4px;
    overflow: hidden;
  }
  .course-img {
    width: 160px;
    height: 96px;
    border-radius: 4px;
    overflow: hidden;
  }
}
</style>